<template>
    <section>
        <div class="row mx-0 px-3 border-bottom py-2">
            <div class="col-auto text-general f-500">
                Impuestos
            </div>
        </div>
        <div class="row mx-0" style="height:calc(100vh - 141px);">
            <div class="col-4 overflow-auto custom-scroll px-0" style="height:calc(100% - 4px)">
                <div class="row mx-0 align-items-end border-bottom pb-2 px-2" style="height:180px;">
                    <div class="col">
                        <el-input v-model="buscar_impuesto" placeholder="Buscar impuesto" class="w-100 br-20" size="small" />
                    </div>
                    <el-tooltip placement="bottom" content="Crear Impuesto" effect="light">
                        <div class="bg-general br-8 shadow d-middle-center cr-pointer" style="height:32px;width:32px;" @click="crearImpuesto()">
                            <i class="icon-plus f-18 text-white" />
                        </div>
                    </el-tooltip>
                </div>
                <div class="d-middle bg-light-f5 text-general px-3 border-bottom" style="height:38px;">
                    <span class="ml-3">Impuesto</span>
                </div>
                <div v-for="(impuesto, i) in impuestos.filter(el => el.nombre.toLowerCase().includes(buscar_impuesto.toLowerCase()))" :key="i" class="row mx-0 align-items-center border-bottom border-right px-2" style="height:38px;">
                    <el-checkbox :key="keyI" v-model="impuesto.checked" :indeterminate="impuesto.indeterminate" class="check-dark" @change="value => checkAllImpuesto(value, impuesto)" />
                    <el-tooltip placement="bottom" effect="light">
                        <div slot="content" style="width:207px;">
                            <p class="text-general2 mt-1 px-2 f-600">{{ impuesto.nombre_factura }}</p>
                            <p class="text-general2 mt-1 px-2 f-14">{{ impuesto.descripcion ? impuesto.descripcion : '-' }}</p>
                        </div>
                        <div class="col px-2 tres-puntos text-general">
                            {{ impuesto.nombre }} ({{ impuesto.porcentaje }} %)
                        </div>
                    </el-tooltip>
                    <el-tooltip placement="bottom" content="Productos que aplican este impuesto" effect="light">
                        <div class="bg-general3 f-17 text-white br-12 px-2">
                            {{ impuesto.productos_count }}
                        </div>
                    </el-tooltip>
                </div>
            </div>
            <div class="col custom-scroll overflow-auto px-0" style="height:calc(100% - -2px)">
                <div class="d-flex border-bottom">
                    <div v-for="(cedi, i) in cedis" :key="i" class="border-left border-bottom box-text border-right" style="width:40px;height:180px;">
                        <p class="tres-puntos">
                            {{ cedi.nombre }}
                            <i v-show="!cedi.estado" class="icon-eye-off" />    
                        </p>
                    </div>
                </div>
                <div class="d-flex bg-light-f5 border-bottom">
                    <div v-for="(cedi, i) in cedis" :key="i" class="d-middle-center border-left border-right py-2" style="width:40px;">
                        <el-checkbox :key="keyI" v-model="cedi.checked" :disabled="!!(buscar_impuesto)" :indeterminate="cedi.indeterminate" class="check-dark mx-3" @change="value => checkAllCedis(value, cedi)" />
                    </div>
                </div>
                <div v-for="(impuesto, i) in impuestos.filter(el => el.nombre.toLowerCase().includes(buscar_impuesto.toLowerCase()))" :key="i" class="d-flex border-bottom" style="height:38px;">
                    <div v-for="(cedi, k) in cedis" :key="k" class="d-middle-center border-bottom border-right" style="width:40px;">
                        <el-checkbox :key="key" :checked="isChecked(impuesto.id, cedi.id)" class="check-dark mx-3" @change="value => check(value, impuesto.id, cedi.id)" />
                    </div>
                </div>
            </div>
        </div>
        <!-- Partials -->
        <modal-crear-impuestos ref="modalCrearImpuestos" @saved="init()" />
        <modal-eliminar ref="modalEliminarImpuesto" titulo="Eliminar Impuesto" mensaje="¿Desea eliminar este impuesto?" @eliminar="eliminar()" />
        <modal-eliminar ref="modalCambiarImpuesto" titulo="Eliminar Impuesto" @eliminar="eliminarCambiar()">
            <p class="text-general text-center p-2">Este impuesto está aplicado a {{ count }} productos.</p>
            <p class="text-general text-center p-2">Para eliminarla debe trasladar los productos en cuestión a otra categoría.</p>
            <div class="row mx-0 justify-center">
                <div class="col-10">
                    <label class="text-general">Impuesto</label>
                </div>
                <div class="col-10">
                    <el-select v-model="idCambio" class="w-100">
                        <el-option
                        v-for="(impuesto, i) in impuestos.filter(el => el.id != idDelete)"
                        :key="i"
                        :label="impuesto.nombre"
                        :value="impuesto.id"
                        />
                    </el-select>
                </div>
            </div>
            <p class="text-general text-center p-2">¿Desea aceptar la acción?</p>
        </modal-eliminar>
    </section>
</template>

<script>
import impuestos from '~/services/configurar/admin/impuestos/impuestos'
const lengthCant = (arr, col, el) => arr.filter(ele => ele[col] == el.id).length
export default {
    components: {
        modalCrearImpuestos: () => import('./partials/modalCrearImpuesto')
    },
    data(){
        return {
            buscar_impuesto: '',
            checked: false,
            showOptions: false,
            cant: 15,
            cedis: [],
            impuestos: [],
            impuestosCedis: [],
            key:false,
            keyI:false,
            count: 0,
            idDelete: null,
            idCambio: null,
        }
    },
    async mounted(){
        await this.init()
    },
    methods: {
        async init(){
            await this.listar()
            this.key = !this.key
        },
        crearImpuesto(){
            this.$refs.modalCrearImpuestos.toggle();
        },
        editarImpuesto(impuesto){
            const cedisSelecteds = this.impuestosCedis.filter(el => el.id_impuesto == impuesto.id).map(el => el.id_cedis)
            this.$refs.modalCrearImpuestos.editar(impuesto, cedisSelecteds);
        },
        eliminarImpuesto({ id }, count){
            this.count = count
            this.idDelete = id
            if(count > 0) this.$refs.modalCambiarImpuesto.toggle();
            else this.$refs.modalEliminarImpuesto.toggle();
        },
        async listar(){
            try {
                const { data }  = await impuestos.listar()

                this.impuestos = data.impuestos.map(el => {
                    const len = lengthCant(data.impuestosCedis, 'id_impuesto', el)
                    el.checked = len == data.cedis.length
                    el.indeterminate = len > 0 && len != data.cedis.length
                    el.showOptions = false
                    return el
                })
                this.impuestosCedis = data.impuestosCedis
                this.cedis = data.cedis.map(el => {
                    const len = lengthCant(data.impuestosCedis, 'id_cedis', el)
                    el.checked = len == data.impuestos.length
                    el.indeterminate = len > 0 && len != data.impuestos.length
                    return el
                })
            } catch (error){
                this.error_catch(error)
            }
        },
        async check(value, impuesto, cedis){
            const model = {
                impuesto,
                cedis,
            }
            if(value) await this.checkImpuesto(model)
            else await this.uncheckImpuesto(model)
            this.recheck()

        },
        async checkImpuesto(model){
            try {
                const { data }  = await impuestos.checkImpuesto(model)
                this.impuestosCedis.push(data.model)
            } catch (error){
                this.error_catch(error)
            }
        },
        async uncheckImpuesto(model){
            try {
                const { data }  = await impuestos.uncheckImpuesto(model)
                let index = this.impuestosCedis.findIndex(el => el.id_cedis == model.cedis && el.id_impuesto == model.impuesto)
                console.log({index});
                this.impuestosCedis.splice(index, 1)
            } catch (error){
                this.error_catch(error)
            }
        },
        recheck(){
            this.impuestos = this.impuestos.map(el => {
                const len = lengthCant(this.impuestosCedis, 'id_impuesto', el)
                el.checked = len == this.cedis.length
                el.indeterminate = len > 0 && len != this.cedis.length
                el.showOptions = false
                return el
            })
            this.cedis = this.cedis.map(el => {
                const len = lengthCant(this.impuestosCedis, 'id_cedis', el)
                el.checked = len == this.impuestos.length
                el.indeterminate = len > 0 && len != this.impuestos.length
                return el
            })
            this.keyI = !this.keyI
        },
        isChecked(impuesto, cedi){
            return !!(this.impuestosCedis.find(el => el.id_impuesto == impuesto && el.id_cedis == cedi))
        },
        async checkAllImpuesto(value, impuesto){
            console.log({value});
            if(value) await this.checkTodoImpuesto(impuesto)
            else await this.uncheckTodoImpuesto(impuesto)
        },
        async checkTodoImpuesto({ id: impuesto }){
            try {
                const { data }  = await impuestos.checkTodoImpuesto({ impuesto })
                await this.init();

            } catch (error){
                this.error_catch(error)
            }
        },
        async uncheckTodoImpuesto({ id: impuesto }){
            try {
                const { data }  = await impuestos.uncheckTodoImpuesto({ impuesto })
                await this.init();
            } catch (error){
                this.error_catch(error)
            }
        },
        async checkAllCedis(value, cedi){
            console.log({value});
            if(value) await this.checkTodoCedis(cedi)
            else await this.uncheckTodoCedis(cedi)
        },
        async checkTodoCedis({ id: cedi }){
            try {
                const { data }  = await impuestos.checkTodoCedis({ cedi })
                await this.init();

            } catch (error){
                this.error_catch(error)
            }
        },
        async uncheckTodoCedis({ id: cedi }){
            try {
                const { data }  = await impuestos.uncheckTodoCedis({ cedi })
                await this.init();
            } catch (error){
                this.error_catch(error)
            }
        },
        async eliminar(){
            try {
                const { data }  = await impuestos.eliminar(this.idDelete)
                if (data.exito){
                    this.notificacion('', 'Eliminado correctamente', 'success')
                    this.$refs.modalEliminarImpuesto.toggle();
                    this.count = 0
                    this.idDelete = null
                    this.idCambio = null
                    await this.init()
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        async eliminarCambiar(){
            try {
                let obj = {
                    id: this.idDelete,
                    cambio: this.idCambio
                }
                const { data }  = await impuestos.eliminarCambiar(obj)
                if (data.exito){
                    this.notificacion('', 'Eliminado correctamente', 'success')
                    this.$refs.modalCambiarImpuesto.toggle();
                    this.count = 0
                    this.idDelete = null
                    this.idCambio = null
                    await this.init()
                }
            } catch (error){
                this.error_catch(error)
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.box-text{
    p{
        writing-mode: vertical-lr;
        height: 150px;
        transform: rotate(180deg)translate(7px, -24px);
    }
}

.my-wrapper{
    width: 80%;
    max-width: 80%;
    overflow-x: scroll;
    white-space: nowrap;
}
</style>
