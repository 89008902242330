import axios from "axios";

const prefix = "impuesto/configurar"

const impuestos = {
    listarCedis: () => axios.get(`${prefix}/listar-cedis`),
    listar: () => axios.get(`${prefix}/listar`),
    crear: data => axios.post(`${prefix}/crear`, data ),
    editar: (id, data) => axios.put(`${prefix}/editar/${id}`, data ),
    checkImpuesto: data => axios.post(`${prefix}/check-impuesto`, data ),
    uncheckImpuesto: data => axios.post(`${prefix}/uncheck-impuesto`, data ),
    checkTodoImpuesto: data => axios.post(`${prefix}/check-todo-impuesto`, data ),
    uncheckTodoImpuesto: data => axios.post(`${prefix}/uncheck-todo-impuesto`, data ),
    checkTodoCedis: data => axios.post(`${prefix}/check-todo-cedis`, data ),
    uncheckTodoCedis: data => axios.post(`${prefix}/uncheck-todo-cedis`, data ),
    eliminar: id => axios.delete(`${prefix}/eliminar/${id}`),
    eliminarCambiar: data => axios.post(`${prefix}/eliminar-cambiar`, data ),

}

export default impuestos